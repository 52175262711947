import React from "react";
import { Link } from "react-router-dom";
import { Button } from "@material-ui/core";
import OnlinePactHome from "./OP_Home/OP_Home";
import OnlinePactCHPAppointment from "./OP_CHP_Appointment/OP_CHP_Appointment";
import OnlinePactCHPConfirmation from "./OP_CHP_Confirmation/OP_CHP_Confirmation";
import OnlinePactCHPTermination from "./OP_CHP_Termination/OP_CHP_Termination";
import OnlinePactReports from "./OP_Reports/OP_Reports";
import OnlinePactAdmin from "./OP_Admin/OP_Admin";

export default function OnlinePactNavBar() {
    return (
        <div className="d-flex jc-space-evenly onlinepactnavbar-header">
            <OnlinePactHome />
            <OnlinePactCHPAppointment />
            <OnlinePactCHPConfirmation /> 
            <OnlinePactCHPTermination />
            {/* <OnlinePactReports /> */}
            <OnlinePactAdmin />
        </div>
    );
}