import React from "react";
import { Link } from "react-router-dom";
import Menu from "material-ui-popup-state/HoverMenu";
import MenuItem from "@material-ui/core/MenuItem";
import Button from "@material-ui/core/Button";
import {
  usePopupState,
  bindHover,
  bindMenu,
} from "material-ui-popup-state/hooks";

export default function OnlinePactHome() {
  const popupState = usePopupState({ variant: "popover", popupId: "demoMenu" });
  return (
    <React.Fragment>
      <Link to="/">
        <Button
          className="reco-button--online-pact-navbtn"
          {...bindHover(popupState)}
        >
          Home
        </Button>
      </Link>
    </React.Fragment>
  );
}
