import React from 'react';
import { Link } from "react-router-dom";
import { makeStyles, ThemeProvider } from '@material-ui/core/styles';
import { CssBaseline, Container, Grid, Typography, Button } from '@material-ui/core';
// import Header from "../../../../SharedComponents/Elements/Header/Header";
// import QLF from "../../../../SharedComponents/common/QLF";
import OnlinePactNavBar from "../OnlinePactNavBar";
// import { lightTheme } from "../../../../SharedComponents/SharedComponentStyles";

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
    },
    onlinepactbanner: {
        boxShadow: `0px 0px 10px #cccccc`,
    },
}));

export default function OnlinePactHomePage() {
    const classes = useStyles();

    return <ThemeProvider>
        <CssBaseline />
        <section className="reco-header">
            {/* <Header /> */}
        </section>
        <main className="reco-main">
            {/* <QLF /> */}
            <Container maxWidth="xl">
                <Grid container justifyContent="center">
                    <Grid item xs={11}>
                        <Typography variant={"h5"} className="page-title">Reco</Typography>
                        <OnlinePactNavBar />
                    </Grid>
                </Grid>
                <Grid container justifyContent="center">
                    <Grid item xs={11} sm={11} md={4} className={classes.onlinepactbanner}>
                        <div className="online-pact-banner"></div>
                    </Grid>
                    <Grid item xs={11} sm={11} md={8} className="op-container-block">
                        <div className="online-pact-pagetitle-block">
                            <h3 className={classes.onlinePactPageTitle}>
                            Reco Tool - Creation and Closing of Complaints
                        </h3>
                            <Typography>
                                Reco Tool is used to raise complaints.....
                    </Typography>
                        </div>
                    </Grid>
                    {/* <Grid item xs={11}>
                        <Link to="/sales" className="back-to-sales-btn">
                            <Button className="reco-button--secondary">&lt;&lt; Back to Sales</Button>
                        </Link>
                    </Grid> */}
                </Grid>
            </Container>
        </main>
    </ThemeProvider>

}