import React from "react";
import { useState, useEffect } from "react";
import axios from "axios";
import MaterialTable from "material-table";
import { useSelector } from "react-redux";
import {
  Grid,
  MenuItem,
  Select,
  FormControl,
  FormHelperText,
} from "@material-ui/core";
import "./UserManagement.scss";
import { tableIcons } from "../../../Utilities/Utility";
import SnackbarCustom from "../../../components/Snackbar/Snackbar";

export default function UserManagementNew() {
  const api = axios.create({
    baseURL: "UserManagement",
  });
  const apiRole = axios.create({
    baseURL: "Role",
  });
  const apiOffice = axios.create({
    baseURL: "Office",
  });
  const apiDispatch = axios.create({
    baseURL: "DispatchCenter",
  });

  const userDetails = useSelector(
    (state) => state.storeUserDetailsReducer.userData
  );

  //for office dropdown
  const [officeMaster, setOfficeMaster] = useState([]);
  const [itemsOffice, setItemsOffice] = useState([]);

  //for Dispatch dropdown
  const [dispatchCenterMaster, setDispatchCenterMaster] = useState([]);
  const [itemsDispatch, setItemsDispatch] = useState([]);

  //For retriving data
  const [tableData, setTableData] = useState([]);

  //for role dropdown
  const [itemsRole, setItemsRole] = useState([]);

  //for snackBar
  const [open, setOpen] = useState(false);
  const [snackMessage, setSnackMessage] = useState("");
  const [alertType, setAlertType] = useState("");

  const handleSnackOpen = (text, type) => {
    setSnackMessage(text);
    setAlertType(type);
    setOpen(true);
  };
  const handleClose = (e) => {
    setOpen(false);
  };

  const staticData = [
    {
      id: 1,
      firstName: "FirstName1",
      lastName: "LastName1",
      emailId: "test@gmail.com",
      gid: "z004bxrf",
      role: { roleName: "Admin" },
      roleId: 1,
    },
    {
      id: 2,
      firstName: "FirstName2",
      lastName: "LastName2",
      emailId: "test2@gmail.com",
      gid: "z004bxrf",
      role: { roleName: "User" },
      roleId: 3,
    },
    {
      id: 3,
      firstName: "FirstName3",
      lastName: "LastName33",
      emailId: "test3@gmail.com",
      gid: "z004bxrf",
      role: { roleName: "External" },
      roleId: 2,
    },
    {
      id: 4,
      firstName: "FirstName4",
      lastName: "LastName4",
      emailId: "test4@gmail.com",
      gid: "z004bxrf",
      role: { roleName: "Admin" },
      roleId: 1,
    },
  ];
  var officeValID = 0;
  function officeValFn(val) {
    console.log("Val", val);
    const offObj = officeMaster.find((c) => c.officeName == val);
    officeValID = offObj?.officeId;
  }
  var dispatchCentrValID = 0;
  function dispatchCenterValFn(val) {
    console.log("Val", val);
    const offObj = dispatchCenterMaster.find(
      (c) => c.dispatchCentreName == val
    );
    dispatchCentrValID = offObj?.id;
  }
  function getOfficeDDLComponent(objProps) {
    return (
      <FormControl error={!objProps.value ? true : false} fullWidth>
        <Select
          value={objProps.value}
          onChange={(e) => {
            objProps.onChange(e.target.value);
            officeValFn(e.target.value);
          }}
        >
          {itemsOffice.map((item, index) => (
            <MenuItem key={index} value={item.value}>
              {item.label}
            </MenuItem>
          ))}
        </Select>
        <FormHelperText>{!objProps.value ? "*Required" : ""}</FormHelperText>
      </FormControl>
    );
  }
  function getDispatchDDLComponent(objProps) {
    return (
      <FormControl fullWidth>
        <Select
          value={objProps.value}
          onChange={(e) => {
            objProps.onChange(e.target.value);
            dispatchCenterValFn(e.target.value);
          }}
        >
          {itemsDispatch.map((item, index) => (
            <MenuItem key={index} value={item.value}>
              {item.label}
            </MenuItem>
          ))}
        </Select>
        {/* <FormHelperText>{!objProps.value ? "*Required" : ""}</FormHelperText> */}
      </FormControl>
    );
  }

  //Representing table headers
  var columns = [
    { title: "id", field: "id", hidden: true },
    {
      title: "FirstName",
      field: "firstName",
      validate: (rowData) =>
        validateRequiredField(rowData, rowData.firstName, "firstName"),
    },
    {
      title: "LastName",
      field: "lastName",
      validate: (rowData) =>
        validateRequiredField(rowData, rowData.lastName, "lastName"),
    },
    {
      title: "Location",
      field: "location",
      validate: (rowData) =>
        validateRequiredField(rowData, rowData.location, "location"),
    },
    {
      title: "Role",
      field: "roleId",
      render: (rowData) => <span>{renderRoleValue(rowData.roleId)}</span>,
      editComponent: (props) => getRoleDDLComponent(props),
      validate: (rowData) => validateRole(rowData.roleId),
      customFilterAndSearch: (term, rowData) =>
        renderRoleValue(rowData.roleId)
          .toLowerCase()
          .includes(term.toLowerCase()),
    },
    {
      title: "Office",
      field: "officeName",
      editComponent: (props) => getOfficeDDLComponent(props),
      validate: (rowData) => validateOffice(rowData.officeName),
      //   customFilterAndSearch: (term, rowData) =>
      //     renderOfficeValue(rowData.salesOffice)
      //       .toLowerCase()
      //       .includes(term.toLowerCase()),
    },
    //{
    //  title: "Dispatch Center",
    //  field: "dispatchCenterName",
    //  //   render: (rowData) => (
    //  //     <span>{renderDispatchValue(rowData.dispatchCenterId)}</span>
    //  //   ),
    //  editComponent: (props) => getDispatchDDLComponent(props),
    //  validate: (rowData) => validateDispatch(rowData.dispatchCenterId),
    //  //   customFilterAndSearch: (term, rowData) =>
    //  //     renderDispatchValue(rowData.dispatchCenterId)
    //  //       .toLowerCase()
    //  //       .includes(term.toLowerCase()),
    //},
    {
      title: "Email Id",
      field: "emailId",
      validate: (rowData) =>
        validateRequiredField(rowData, rowData.emailId, "emailId"),
    },
    {
      title: "GID",
      field: "gid",
      validate: (rowData) => validateRequiredField(rowData, rowData.gid, "gid"),
    },
  ];

  function getRoleDDLComponent(objProps) {
    return (
      <FormControl error={!objProps.value ? true : false} fullWidth>
        <Select
          value={objProps.value}
          onChange={(e) => {
            objProps.onChange(e.target.value);
          }}
        >
          {itemsRole.map((item, index) => (
            <MenuItem key={index} value={item.value}>
              {item.label}
            </MenuItem>
          ))}
        </Select>
        <FormHelperText>{!objProps.value ? "*Required" : ""}</FormHelperText>
      </FormControl>
    );
  }
  function renderRoleValue(value) {
    let obj = {};
    if (value === 6) {
      return "Internal user";
    } else {
      if (itemsRole && itemsRole.length > 0) {
        obj = itemsRole.find((c) => c.value == value);
      }
      if (obj) {
        return obj.label;
      } else {
        return "";
      }
    }
  }
  function validateRole(value) {
    return !value
      ? { isValid: false, helperText: "*Required" }
      : { isValid: true, helperText: "" };
  }
  function validateOffice(value) {
    return !value
      ? { isValid: false, helperText: "*Required" }
      : { isValid: true, helperText: "" };
  }
  function validateDispatch(value) {
    return { isValid: true, helperText: "" };
  }

  useEffect(() => {
    getRoles();
    getUsers();
    getOffice();
    getDispatchCenter();
  }, []);

  const getUsers = () => {
    api
      .get("/getAllUsers")
      .then((res) => {
        setTableData(res.data);
        console.log("All User Data", res.data);
      })
      .catch((error) => {
        console.log("Error in hitting api at page load.", error);
      });
  };

  const getRoles = async () => {
    apiRole
      .get("/getAllRoles")
      .then((res) => {
        setItemsRole(
          res.data.map(({ roleName, id }) => ({ label: roleName, value: id }))
        );
      })
      .catch((error) => {
        console.log("Error in hitting api at page load.");
      });
  };

  const getOffice = async () => {
    apiOffice
      .get("/getAllOffices")
      .then((res) => {
        console.log("Office Master", res);
        setOfficeMaster(res.data);
        setItemsOffice(
          res.data.map(({ officeName, officeId }) => ({
            label: officeName,
            value: officeName,
          }))
        );
      })
      .catch((error) => {
        console.log("Error in hitting api at page load.");
      });
  };

  const getDispatchCenter = async () => {
    apiDispatch
      .get("/getAllDispatchCenters")
      .then((res) => {
        console.log("DidspatchCen", res);
        setDispatchCenterMaster(res.data);
        setItemsDispatch(
          res.data.map(({ dispatchCentreName, dispatchCentreId }) => ({
            label: dispatchCentreName,
            value: dispatchCentreName,
          }))
        );
      })
      .catch((error) => {
        console.log("Error in hitting api at page load.", error);
      });
  };

  //Add New User
  const handleRowAdd = (newData, resolve, reject) => {
    newData.createdBy = userDetails.id;
    // newData.DispatchCenterId = newData.dispatchCenterName;
    newData.SalesOffice = officeValID;
    newData.dispatchCenterId = dispatchCentrValID;
    api
      .post("/postUser", newData)
      .then((res) => {
        let dataToAdd = [...tableData];
        if (res.data === 2) {
          setTableData([...dataToAdd]);
          handleSnackOpen("User is already exist.", "warning");
          reject();
        } else {
          dataToAdd.push(newData);
          setTableData(dataToAdd);
          resolve();
          getUsers();
          handleSnackOpen("User added successfully.", "success");
        }
      })
      .catch((error) => {
        reject();
      });
  };

  function validateRequiredField(rowData, value, fieldName) {
    let result = true;
    let requiredVal = true;
    let helperTextVal;
    const maxLength = 50;
    const maxGidLength = 8;
    const nameLength = 100;
    const exp = /^[a-zA-Z]*$/;
    const expGid = /^[\/a-zA-Z0-9]*$/;
    /*const expEmail = /^[a-zA-Z0-9@.]*$/;*/
    const expEmail = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/;

    if (fieldName === "firstName") {
        requiredVal = !value ? false : true;
        result = exp.test(value);
        helperTextVal = "Invalid Firstname";
        if (value) {
            if (value.length > nameLength) {
                result = false;
                helperTextVal = "First Name should not exceeds the Max length: 100";
            }
        }
    } else if (fieldName === "lastName") {
        requiredVal = !value ? false : true;
        result = exp.test(value);
        helperTextVal = "Invalid Lastname";
        if (value) {
            if (value.length > nameLength) {
                result = false;
                helperTextVal = "Last Name should not exceeds the Max length: 100";
            }
        }
    } else if (fieldName === "location") {
        requiredVal = !value ? false : true;
        result = exp.test(value);
        helperTextVal = "Invalid Location";
        if (value) {
            if (value.length > nameLength) {
                result = false;
                helperTextVal = "Location should not exceeds the Max length: 100";
            }
        }
    } else if (fieldName === "emailId") {
        requiredVal = !value ? false : true;
        result = expEmail.test(value);
        helperTextVal = "Invalid Emailid";
        if (result == false);
        {
            helperTextVal = "Invalid Email Format";
        }
        //if (value) {
        //    if (value.length > maxLength) {
        //        result = false;
        //        helperTextVal = "Email Id should not exceeds the Max length: 50";
        //    }
        //}
    } else if (fieldName === "gid") {
        requiredVal = !value ? false : true;
        result = expGid.test(value);
        helperTextVal = "Invalid Gid";
        if (value) {
            if (value.length != maxGidLength) {
                result = false;
                helperTextVal = "Invalid Gid";
            }
        }
    }

    return !requiredVal
        ? { isValid: false, helperText: "*Required" }
        : !result
            ? { isValid: false, helperText: helperTextVal }
            : { isValid: true, helperText: "" };
}

  //Updating the row
  const handleRowUpdate = (newData, oldData, resolve, reject) => {
    newData.ModifiedBy = userDetails.id;
    newData.SalesOffice = officeValID == 0 ? oldData.salesOffice : officeValID;
    newData.dispatchCenterId = dispatchCentrValID == 0 ? oldData.dispatchCenterId : dispatchCentrValID;
    api
      .put("/updateUser", newData)
      .then((res) => {
        const dataUpdate = [...tableData];
        if (res.data === 2) {
          setTableData([...dataUpdate]);
          handleSnackOpen("User is already exist.", "warning");
          // resolve();
          reject();
        } else {
          const index = oldData.tableData.id;
          dataUpdate[index] = newData;
          setTableData([...dataUpdate]);
          //resolve();
          reject();
          handleSnackOpen("User updated successfully.", "success");
        }
      })
      .catch((error) => {
        // resolve();
        reject();
      });
  };

  //Deleting user
  const handleRowDelete = (oldData, resolve, reject) => {
    api
      .delete("/deleteUser/" + oldData.id)
      .then((res) => {
        const dataDelete = [...tableData];
        const index = oldData.tableData.id;
        dataDelete.splice(index, 1);
        setTableData([...dataDelete]);
        //resolve();
        reject();
        handleSnackOpen("User deleted successfully.", "success");
      })
      .catch((error) => {
        //resolve();
        reject();
      });
  };

  const tableHeaderStyle = {
    backgroundColor: "var(--light-sand)",
    color: "var(--dark-blue)",
  };
  const tableOptions = {
    headerStyle: tableHeaderStyle,
    showTitle: false,
    draggable: false,
    pageSize: 5,
    pageSizeOptions: [5, 10, 25, 50],
    paginationPosition: "bottom",
    //paging: false,
    maxBodyHeight: "600px",
    // exportButton: true,
    // exportAllData: true
  };

  return (
    <div>
      <div className="App">
        <Grid container spacing={1}>
          <Grid item xs={12} className="d-flex jc-space-bt">
            <Grid item xs={9} className="text-left ml-1">
              <h2>User List</h2>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <MaterialTable
              title="User List"
              //title={getTableTitle()}
              columns={columns}
              data={tableData}
              icons={tableIcons}
              // isLoading={isLoader}
              options={tableOptions}
              editable={{
                isEditable: (rowData) => rowData.roleId !== 6,
                isDeletable: (rowData) => rowData.roleId !== 6,
                onRowUpdate: (newTableData, oldTableData) =>
                  new Promise((resolve, reject) => {
                    handleRowUpdate(
                      newTableData,
                      oldTableData,
                      resolve,
                      reject
                    );
                  }),
                onRowAdd: (newTableData) =>
                  new Promise((resolve, reject) => {
                    handleRowAdd(newTableData, resolve, reject);
                  }),
                onRowDelete: (oldTableData) =>
                  new Promise((resolve, reject) => {
                    handleRowDelete(oldTableData, resolve, reject);
                  }),
              }}
            />
          </Grid>
        </Grid>
        <SnackbarCustom
          open={open}
          message={snackMessage}
          alertType={alertType}
          handleClose={handleClose}
        />
      </div>
    </div>
  );
}
