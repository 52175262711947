import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Button,
    Tooltip,
    makeStyles,
} from "@material-ui/core";
import axios from "axios";
import { tableHeaderStyle } from "../../Themes/LightTheme";
import { tableIconsInputScreen } from "../../Utilities/Utility";
import DownloadIconOutlined from "@material-ui/icons/GetApp";
import MaterialTable from "material-table";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";

const api = axios.create({
    baseURL: "CloseComplaint",
});
const deleteAPI = axios.create({
    baseURL: "Complaint",
});

const useStyles = makeStyles((theme) => ({
    heading: {
        fontSize: theme.typography.pxToRem(15),
        fontWeight: theme.typography.fontWeightBold,
    },
    accordioncolor: {
        background: "#D1FFF2",
    },
    accordionMargin: {
        marginTop: "10px",
    },
}));

export const getAttachments = async (complaintNor) => {
    const response = await api.post("/GetTempAttachmntList", complaintNor);
    return response;
};

const multipleFileColumns = [
    { title: "File Name", field: "filename" },
    {
        title: "Download File",
        width: "10%",
        render: (rowData) => downloadIconButton(rowData),
    },
    { title: "Uploaded By", field: "createdName" }
];

const downloadIconButton = (rowData) => {
    return (
        <Button href={rowData.fileUrl} color="transparent" target="_blank" download>
            <Tooltip title="Download Attachment">
                <DownloadIconOutlined />
            </Tooltip>
        </Button>
    );
};
export const DisplayMultipleAttachment = (props) => {
    const classes = useStyles();
    return (
        <>
            <Accordion className={classes.accordionMargin}>
                <AccordionSummary
                    className={classes.accordioncolor}
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                >
                    <h3>Attachments</h3>
                </AccordionSummary>
                <AccordionDetails>
                    <MaterialTable
                        columns={multipleFileColumns}
                        data={props.tableData}
                        icons={tableIconsInputScreen}
                        //isLoading={isLoader}
                        options={{
                            headerStyle: tableHeaderStyle,
                            draggable: false,
                            showTitle: false,
                            pageSize: 3,
                            pageSizeOptions: [3, 5, 10, 50],
                            paginationPosition: "bottom",
                            //paging: false,
                            maxBodyHeight: "600px",
                            sorting: true,
                        }}
                    />
                </AccordionDetails>
            </Accordion>
        </>
    );
};

export const deleteTempAttchments = (loginId) => {
    deleteAPI
        .delete("/deleteAttachmentsBasedOnLoginId/" + loginId)
        .then((res) => {
            console.log("Temp attachments deleted", res);
        })
        .catch((error) => {
            console.log("Error in deleting temp attchments", error);
        });
};