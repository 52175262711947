import {
  authUserAction,
  storeUserDetailsAction,
  saveAccessTokenAction,
  errorMessageAction,
  isUserAuthorizedAction,
} from "../Redux/Actions/actions";
//import { baseApiURL } from "../Utilities/utility";
let gID,
  generatedToken = "";

// function btoaSecure(value) {
//   const { btoa } = require("abab");
//     let currDate = new Date().toDateString();
//     let valueModified = value + " " + currDate;
//     let valueSecured = btoa(btoa(valueModified));
//     return valueSecured;
// }

// function atobSecure(value) {
//   const { atob } = require("abab");
//   let arrValue = atob(atob(value)).split(" ");
//   let valueActual = arrValue[0];
//   return valueActual;
// }

const baseApiURL = window.location.origin;

export function authenticateUserBasedOnValidToken(dispatch, newToken, gID) {
  const systemUser = {
    Gid: gID,
    // password: "password",
  };

  const options = {
    method: "post",
    mode: "cors",
    headers: {
      Accept: "application/json, text/plain, */*",
      "Content-Type": "application/json",
      "Access-Control-Allow-Credentials": true,
      Authorization: "Bearer " + newToken,
      // "Access-Control-Allow-Origin": "*",
    },
    body: JSON.stringify(systemUser),
  };

  return fetch(baseApiURL + "/Login/AuthenticateGId", options)
    .then((response) => response.json())
    .then((data) => {
      if (data.result.response) {
        // dispatch(isUserAuthorizedAction(true));
        // dispatch(storeUserDetailsAction(data.result.objResult[0]));
        dispatch(storeUserDetailsAction(data.result.objResult[0]));
        return data.result;
      } else {
        // dispatch(isUserAuthorizedAction(false));
        // dispatch(storeUserDetailsAction(""));
        return data.result;
      }
    })
    .catch((error) => {
      // return false;
      console.log("Error : ", error);
      return {
        response: false,
        responseMsg: "Exception occured while authenticating user.",
      };
    });
}

// We are getting ONLY token from this api
export function validateTheUserBasedOnApi(dispatch, auth) {
  const arr = auth.user.profile.sub.split("|");
  let gid = arr && arr.length ? arr[1] : "";
  const systemUser = {
    GID: gid,
  };

  const options = {
    method: "post",
    mode: "cors",
    headers: {
      Accept: "application/json, text/plain, */*",
      "Content-Type": "application/json",
      "Access-Control-Allow-Credentials": true,
      // "Access-Control-Allow-Origin": "*",
    },
    body: JSON.stringify(systemUser),
  };

  return fetch(baseApiURL + "/Login/ValidateRequest", options)
    .then((res) => res.json())
    .then((res_token) => {
      if (auth !== undefined && auth.user) {
        const arr = auth.user.profile.sub.split("|");
        gID = arr && arr.length ? arr[1] : "";
        dispatch(authUserAction(gID));
      }
      generatedToken = res_token.token;
      dispatch(saveAccessTokenAction(generatedToken));

      return generatedToken;
    })
    .catch((error) =>
      dispatch(errorMessageAction("Invalid Email or Password"))
    );
}

// generate token for forgot password
export function generateTokenOnly(dispatch) {
  const systemUser = {
    userEmailId: "hiremath.basavaraj.ext@siemens.com",
    // password: "password",
  };
  const options = {
    method: "post",
    mode: "cors",
    headers: {
      Accept: "application/json, text/plain, */*",
      "Content-Type": "application/json",
      "Access-Control-Allow-Credentials": true,
      // "Access-Control-Allow-Origin": "*",
    },
    body: JSON.stringify(systemUser),
  };

  return fetch(baseApiURL + "/Login/ValidateRequest", options)
    .then((res) => res.json())
    .then((res_token) => {
      generatedToken = res_token.token;
      dispatch(saveAccessTokenAction(generatedToken));
      return generatedToken;
    })
    .catch((error) => {
      return error;
    });
}

export function authenticateExternalUser(newToken, userData) {
  const options = {
    method: "post",
    mode: "cors",
    headers: {
      Accept: "application/json, text/plain, */*",
      "Content-Type": "application/json",
      "Access-Control-Allow-Credentials": true,
      Authorization: "Bearer " + newToken,
      // "Access-Control-Allow-Origin": "*",
    },
    body: JSON.stringify(userData),
  };

  return fetch(baseApiURL + "/Login/AuthenticateExternalUser", options)
    .then((response) => response.json())
    .then((data) => {
      if (data.result) {
        return data.result;
      } else {
        return false;
      }
    })
    .catch((error) => {
      return false;
    });
}
export function authenticateContractUser(newToken, loginData) {
  const options = {
    method: "post",
    mode: "cors",
    headers: {
      Accept: "application/json, text/plain, */*",
      "Content-Type": "application/json",
      "Access-Control-Allow-Credentials": true,
      Authorization: "Bearer " + newToken,
      // "Access-Control-Allow-Origin": "*",
    },
    body: JSON.stringify(loginData),
  };

  return fetch(baseApiURL + "/Login/AuthenticateContractUser", options)
    .then((response) => response.json())
    .then((data) => {
      if (data.result) {
        return data.result;
      } else {
        return false;
      }
    })
    .catch((error) => {
      return false;
    });
}
