import React from "react";
import { Link } from "react-router-dom";
import Menu from "material-ui-popup-state/HoverMenu";
import MenuItem from "@material-ui/core/MenuItem";
import Button from "@material-ui/core/Button";
import {
  usePopupState,
  bindHover,
  bindMenu,
} from "material-ui-popup-state/hooks";
import { useSelector } from "react-redux";
import * as roles from "../../../../Utilities/Utility.js";

export default function OnlinePactAdmin() {
  const popupState = usePopupState({ variant: "popover", popupId: "demoMenu" });
  const userDetails = useSelector(
    (state) => state.storeUserDetailsReducer.userData
  );
  let roleName = userDetails.roleName ? userDetails.roleName : "";
  return (
    <React.Fragment>
      {roleName.toLowerCase() == roles.adminRole ? (
        <>
          <Button
            className="reco-button--online-pact-navbtn"
            {...bindHover(popupState)}
          >
            Admin
          </Button>
          <Menu
            {...bindMenu(popupState)}
            getContentAnchorEl={null}
            anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
            transformOrigin={{ vertical: "top", horizontal: "left" }}
          >
            <MenuItem onClick={popupState.close} className="op-list-item">
              <Link to="/userManagement">User Management</Link>
            </MenuItem>
            <MenuItem onClick={popupState.close} className="op-list-item">
              <Link to="/dispatchCenterMaster">Dispatch Center Master</Link>
            </MenuItem>
          </Menu>
        </>
      ) : (
        <></>
      )}
    </React.Fragment>
  );
}
