import React, { useEffect, useState } from "react";
import { BrowserRouter } from "react-router-dom";
import "./assets/breakpoint.scss";
import "./App.css";
import { LoginRoute } from "./Routes/LoginRoute";
import {
    authenticateUserBasedOnValidToken,
    generateTokenOnly,
    validateTheUserBasedOnApi,
} from "./APIs/api_Login";
import { useAuth } from "react-oidc-context";
import { useDispatch, useSelector } from "react-redux";
import { saveAzureTokenAction, storeUserDetailsAction } from "../src/Redux/Actions/actions";
import { MasterRoute } from "./Routes/MasterRoute";
import Header from "./components/Header/Header";
import Loader from "./components/Loader/Loader";
import { Dialog, DialogContent, DialogTitle } from "@material-ui/core";
import UnauthorizedAccess from "./components/UnauthorizedAccess/UnauthorizedAccess";
import IdleTimer from "react-idle-timer";
import { saveAccessTokenAction } from "./Redux/Actions/actions";
import { UnAuthRoute } from "./Routes/UnAuthorizedRoute";
import { useMsal } from
    "@azure/msal-react"
    ;
// import {
//   authenticateUserWithMyID,
//   generateJwtForAPI,
// } from "./Redux/APIs/api_Login";

export default function App() {
    const auth = useAuth();
    const dispatch = useDispatch();
    const token = useSelector((state) => state.saveTokenReducer.token);
    const userDetails = useSelector(
        (state) => state.storeUserDetailsReducer.userData
    );
    const isUserAuthorized = useSelector((state) => state.isUserAuthorized);
    const [isLoader, setIsLoader] = useState(false);
    const [dialogOpen, setDialogOpen] = useState(false);
    const [unAuthText, setUnAuthText] = useState("");
    const { instance, accounts } = useMsal();
    const activeAccount = instance.getActiveAccount();
    const handleClose = (e, reason) => {
        if (reason === "backdropClick") {
            return;
        }
        setDialogOpen(false);
    };
    function sessionExpire(auth, dispatch) {
        var newurl =
            window.location.protocol +
            "//" +
            window.location.host +
            window.location.pathname;
        window.history.pushState({ path: newurl }, "", `/`);
        localStorage.clear();
        sessionStorage.clear();
        if (auth !== undefined) {
            dispatch(storeUserDetailsAction(""));

            caches.keys().then((names) => {
                names.forEach((name) => {
                    caches.delete(name);
                });
            });
        }
        window.location.href = "/";
    }
    function authenticateUserWithGID(azureToken, gid) {
        let data = { gid: gid };
        authenticateUserBasedOnValidToken(dispatch, azureToken, gid)
            .then((response) => {
                if (response.response) {
                    setUnAuthText("");
                    setDialogOpen(false);
                    setIsLoader(false);
                } else {
                    setIsLoader(false);
                    setUnAuthText(response.responseMsg);
                    setDialogOpen(true);
                }
            })
            .catch((error) => {
                setIsLoader(false);
                setUnAuthText("Exception occured while autheniticating user.");
                setDialogOpen(true);
            });
    }
    //useEffect(() => {
    //    if (auth.user) {
    //        setIsLoader(true);
    //        if (token) {
    //            const arr = auth.user.profile.sub.split("|");
    //            let gid = arr && arr.length ? arr[1] : "";
    //            authenticateUserWithGID(gid);
    //        } else {
    //            generateTokenOnly(dispatch)
    //                .then((response) => setIsLoader(false))
    //                .catch((error) => setIsLoader(false));
    //        }
    //    }
    //    if (auth.error) {
    //        setDialogOpen(true);
    //    }
    //}, [auth, token, dispatch]);
    useEffect(() => {
        setIsLoader(true);
        if (activeAccount) {
           
            let gid = accounts[0].idTokenClaims.gid;

            dispatch(saveAzureTokenAction(activeAccount.idToken));
            authenticateUserWithGID(activeAccount.idToken, gid);
            setIsLoader(false);
        } else {
            setIsLoader(false);
        }
    }, [accounts]);
    

    return (

        <BrowserRouter>
            <div className="ssiat-master-block">
                {userDetails.roleId !== 0 && userDetails && userDetails.roleId !== undefined ? (
                    <>
                        <Header>Header Component</Header>
                        <main className="main-component">{MasterRoute}</main>
                    </>
                ) : (
                    LoginRoute
                )}
            </div>
            <div>
                <IdleTimer
                    timeout={1000 * 60 * 15}
                    onIdle={sessionExpire}
                    debounce={250}
                />
            </div>
            <>
                <Dialog
                    disableEscapeKeyDown
                    open={dialogOpen}
                    onClose={handleClose}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogTitle id="alert-dialog-title">
                        {auth.error ? "MyID Session Expired" : "Un-Authorized"}
                    </DialogTitle>
                    <DialogContent>
                        <UnauthorizedAccess
                            errorText={
                                auth.error ? "MyID Error :" + auth.error?.stack : unAuthText
                            }
                        />
                    </DialogContent>
                </Dialog>
            </>
            {isLoader ? <Loader loaderText="Loading...!" /> : <></>}
        </BrowserRouter>


        //     <BrowserRouter>
        //         {/* <div className="ssiat-master-block">
        //     {(userDetails && userDetails.gid === "" && userDetails.roleId !== 0) ||
        //     (auth.user && userDetails.roleId !== 0) ? (
        //       <>
        //         <Header>Header Component</Header>
        //         <main className="main-component">{MasterRoute}</main>
        //       </>
        //     ) : auth.isLoading || isLoader ? (
        //       <Loader />
        //     ) : isAuthorized === false && auth.user ? (
        //       <UnauthorizedAccess errorText="You are not allowed to view this page" />
        //     ) : (
        //       LoginRoute
        //     )}
        //   </div> */}

        //         {/* <div className="ssiat-master-block">
        //     {auth.isLoading || isLoader ? (
        //       <Loader />
        //     ) : userDetails && userDetails.roleId !== 0 && auth.user ? (
        //       <>
        //         <Header>Header Component</Header>
        //         <main className="main-component">{MasterRoute}</main>
        //       </>
        //     ) : auth.user && userDetails.roleId == 0 ? (
        //       UnAuthRoute
        //     ) : (
        //       LoginRoute
        //     )}
        //   </div> */}

        //         {auth.isLoading || isLoader ? (
        //             <Loader />
        //         ) : (userDetails && userDetails.roleId !== 0) || auth.user ? (
        //             userDetails.roleName !== "" ? (
        //                 <>
        //                     <Header>Header Component</Header>
        //                     <main className="main-component">{MasterRoute}</main>
        //                 </>
        //             ) : isUserAuthorized ? (
        //                 userDetails.roleId <= 0 ? (
        //                     <Loader />
        //                 ) : (
        //                     UnAuthRoute
        //                 )
        //             ) : (
        //                 <></>
        //             )
        //         ) : (
        //             LoginRoute
        //         )}

        //         <div>
        //             <IdleTimer
        //                 timeout={1000 * 60 * 15}
        //                 onIdle={sessionExpire}
        //                 debounce={250}
        //             />
        //         </div>
        //         <>
        //             <Dialog
        //                 disableEscapeKeyDown
        //                 open={dialogOpen}
        //                 onClose={handleClose}
        //                 aria-labelledby="alert-dialog-title"
        //                 aria-describedby="alert-dialog-description"
        //             >
        //                 <DialogTitle id="alert-dialog-title">
        //                     {auth.error ? "MyID Session Expired" : "Un-Authorized"}
        //                 </DialogTitle>
        //                 <DialogContent>
        //                     <UnauthorizedAccess
        //                         errorText={
        //                             auth.error ? "MyID Error :" + auth.error?.stack : unAuthText
        //                         }
        //                     />
        //                 </DialogContent>
        //             </Dialog>
        //         </>
        //     </BrowserRouter>

    );
}
