import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Container, Grid } from "@material-ui/core";
import "./Home.scss";
import OnlinePactNavBar from "../Sales/OnlinePact/OnlinePactNavBar";
import Cognisphere from "../../assets/images/Cognisphere_Transparent.png";
import {
  closingComDetailsAction,
  complaintDetailDataAction,
  escalateRemarksAction,
} from "../../Redux/Actions/actions";

export default function NewHome() {
  const userDetails = useSelector(
    (state) => state.storeUserDetailsReducer.userData
  );
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(escalateRemarksAction(""));
    dispatch(
      closingComDetailsAction({
        status: "",
        remarks: "",
      })
    );
    dispatch(complaintDetailDataAction(""));
  });
  return (
    <>
      <img
        src={Cognisphere}
        alt="Cognisphere"
        className="homepage-cognisphere"
      />
      <div className="bg-landing-page">
        {userDetails.firstName != "" && userDetails.firstName != undefined ? <OnlinePactNavBar /> : <></>}

        <Container maxWidth="lg">
          <Grid container className="homepage-container">
            <Grid item xs={12} sm={12} md={12} lg={5} className="z-9">
              <div className="logged-in-user">
                <h1 className="logged-in-user-heading">
                  <span className="logged-in-greetings">Hello,</span>
                  <span className="logged-in-user-name">
                    {userDetails.firstName}
                  </span>
                </h1>
                <p className="logged-in-project-desc">Welcome to RECO</p>
              </div>
            </Grid>
          </Grid>
        </Container>
      </div>
    </>
  );
}
